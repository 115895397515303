<template>
    <OApryseEditor 
        readOnly 
        :tagRecognizer="!props.readOnly"
        :annotsDataObject="props.annotsDataObject"
        :pdfSrc="{ viewName: `${props.viewName}`, primKey: `${props.primKey}`, fileName: `${props.fileName}` }"
        ref="apryseRef"
        @EditorMounted="editorMounted"
        @DocumentLoaded="showObjects"
        disableInitialAnnotationsLoad
    >
        <template #header-top-left-icon-1>
            <i v-if="!props.readOnly" class="bi bi-magic" @click="() => {searchModalRef.show()}" v-tooltip="{title: 'Search for tags', placement: 'bottom', trigger: 'hover', delay: { show: 600, hide: 100 }}"></i>
        </template>
        <template #header-top-left>
            <slot v-if="props.readOnly" name="show-objects-btn"></slot>
            <div v-if="!props.readOnly && userSession.isDeveloper" style="font-size: 20px; marign-top: 5px; margin-left: 5px; cursor:pointer;" @click="() => { createAnnotationModalRef.showDialog(); annotationModalRef.hideDialog();}">
                <svg width="20" height="20" fill="currentColor" class="bi bi-code-slash" viewBox="0 0 20 20">
                    <path stroke="grey" d="M10.478 1.647a.5.5 0 1 0-.956-.294l-4 13a.5.5 0 0 0 .956.294zM4.854 4.146a.5.5 0 0 1 0 .708L1.707 8l3.147 3.146a.5.5 0 0 1-.708.708l-3.5-3.5a.5.5 0 0 1 0-.708l3.5-3.5a.5.5 0 0 1 .708 0m6.292 0a.5.5 0 0 0 0 .708L14.293 8l-3.147 3.146a.5.5 0 0 0 .708.708l3.5-3.5a.5.5 0 0 0 0-.708l-3.5-3.5a.5.5 0 0 0-.708 0"/>
                </svg>
            </div>
        </template>
    </OApryseEditor>
    <OObjectsLookup ref="objLookupRef" :bind="objectSelected" :allowInsertingNew="!props.readOnly" />
    <OModal ref="searchModalRef">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{$t('Search For Tags')}}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <ORowContainer class="row-container">
                        <o-data-grid :data-object="props.syntaxDataObject" filter-row hideGridMenu disable-batch-records noFooter hideMultiselectColumn :showNewRecordsPanel="false">
                            <o-column field="Name" headerName="Syntax" :width="300" editable sortable :filter="false"></o-column>
                            <o-column field="OrgUnit" :width="300" editable sortable :filter="false"></o-column>
                        </o-data-grid>
                    </ORowContainer>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{$t('Close')}}</button>
                    <button type="button" class="btn btn-primary" @click="searchForTags">
                        {{$t('Search For Tags')}}
                    </button>
                </div>
            </div>
        </div>
    </OModal>
    <ODialog ref="objectsModalRef" :title="$t('Found Objects')" size="lg">
        <div class="o365-dialog-body" style="max-height: 60vh;">
            <o-data-grid :data="extractedObjects" filter-row hideGridMenu disable-batch-records noFooter hideMultiselectColumn :showNewRecordsPanel="false" :rowclickhandler="row => selectAnnotation(row)">
                <o-column field="Object" headerNAme="Extracted Objects" :width="350" sortable></o-column>
                <o-column field="Object_ID" headerNAme="System Objects" :width="150" sortable></o-column>
            </o-data-grid>
        </div>
    </ODialog>
    <!-- For Developers Only -->
    <ODialog ref="annotationModalRef" :title="$t('Modify Annotation')">
        <div class="o365-dialog-body">
            <div class="modal-content">
                <OTextArea v-model="props.annotsDataObject.current.Annot" class="form-control" rows="25"/>
                <div class="modal-footer mt-2">
                    <button type="button" class="btn btn-primary" @click="props.annotsDataObject.save(); props.annotsDataObject.load()">{{$t('Save')}}</button>
                </div>
            </div>
        </div>
    </ODialog>
    <ODialog ref="createAnnotationModalRef" :title="$t('Create Annotation')">
        <div class="o365-dialog-body">
            <div class="modal-content">
                <div class="d-flex flex-row">
                    <span class="text-center me-1">x1<input type="text" class="form-control" style="width:60px;" v-model="annot.x1"></span>
                    <span class="text-center me-1">y1<input type="text" class="form-control" style="width:60px;" v-model="annot.y1"></span>
                    <span class="text-center me-1">x2<input type="text" class="form-control" style="width:60px;" v-model="annot.x2"></span>
                    <span class="text-center me-1">y2<input type="text" class="form-control" style="width:60px;" v-model="annot.y2"></span>
                    <span class="text-center me-1">title<input type="text" class="form-control" style="width:100px;" v-model="annot.title"></span>
                </div>
                <div class="modal-footer mt-2">
                    <button type="button" class="btn btn-primary" @click="createAnnotationFromTemplate">{{$t('Create')}}</button>
                </div>
            </div>
        </div>
    </ODialog>
</template>

<script setup lang="ts">
    import type { DataObject } from 'o365-dataobject';
    import { ref, watch } from 'vue';
    import { OApryseEditor } from 'o365-apryse';
    import { OObjectsLookup } from 'o365-system-lookups';
    import { ODialog } from 'o365-ui-components';
    import { vTooltip, useDataObjectEventListener } from 'o365-vue-utils';
    import { alert as o365alert} from 'o365-vue-services';
    import { userSession } from 'o365-modules';
    import TagRecognizer from 'arena.modules.TagRecognizer.ts';

    export interface Props {
        primKey: string
        id: number
        viewName: string
        fileName: string
        fileRef: string
        showObjectsRef: boolean
        annotsDataObject: DataObject
        objectsDataObject: DataObject
        syntaxDataObject?: DataObject
        readOnly?: boolean
        navigateToObject?: Function
    };
    
    const props = defineProps<Props>();
    const apryseRef = ref();
    const objLookupRef = ref();
    const searchModalRef = ref();
    const annotationModalRef = ref();
    const createAnnotationModalRef = ref();
    const objectsModalRef = ref();

    var annot = { x1: 220, y1: 200, x2:500, y2: 250, title: 'annot' }
    var extractedObjects: any = []
    var instance: WebViewerInstance = undefined;
    var lastSelectedAnnotation = undefined;
    var annotationsManager = undefined;

    watch(() => props.showObjectsRef, async () => {
        await showObjects(null);
    })

    useDataObjectEventListener(props.annotsDataObject, 'BeforeSave', function(event, updated, row) {
        let object_json = instance.Core.annotationManager.getAnnotationsList()
                    .find(annot => annot.Id == row.AnnotID)
                    .getCustomData('Object')
        
        if(object_json){
            event.values.Object_ID = JSON.parse(object_json).ObjectRequirement_ID
        }
    }); 

    async function showObjects(annotManager){
        if(annotManager){
            annotationsManager = annotManager;
            if(!props.readOnly){
                props.annotsDataObject.load()
                await annotationsManager?.loadAnnots();
            }
        }
        if(!props.showObjectsRef){
            instance.Core.annotationManager.hideAnnotations(instance.Core.annotationManager.getAnnotationsList());
        }
        else{
            await annotationsManager?.loadAnnots();
            instance.Core.annotationManager.showAnnotations(instance.Core.annotationManager.getAnnotationsList());
        }   
    }

    function viewOnly(viewer: WebViewerInstance){
        instance = viewer;
        instance.UI.setAnnotationContentOverlayHandler((annotation: Core.Annotations.Annotation) => { 
            if(annotation.getCustomData("Object")){
                let json = JSON.parse(annotation.getCustomData("Object"))
                let object = props.objectsDataObject.data.find(row => row.ID == json.ObjectRequirement_ID)
                if(object){
                    const div = document.createElement('div'); 
                    div.innerHTML = `
                    <div style="display: flex; align-items: flex-start;">
                        <div style="flex: 1">
                            <p><b>Object Type:</b> ${object.ObjectType}</p>
                            <p><b>Name / Tag.No:</b> ${object.Object}</p>
                            <p><b>Description:</b> ${object.ObjectDescription ?? ''}</p>
                            <p><b>Org Unit:</b> ${object.OrgUnit}</p>
                        </div>
                    </div>`
                    return div;
                }
            }
            else{
                const div = document.createElement('div')
                div.appendChild(document.createTextNode(`Created by: ${annotation.Author}`)); 
                return div;
            } 
        });

        instance.Core.Annotations.SelectionModel.setCustomHandlers(instance.Core.Annotations.BoxSelectionModel, {
            testSelection: (annotation, x, y, pageMatrix, zoom, rotation, { selectionModel, originalTestSelection }) => {
                if (annotation instanceof instance.Core.Annotations.RectangleAnnotation) {
                    return instance.Core.Annotations.SelectionAlgorithm.boundingRectTest(annotation, x, y, zoom);
                }
            }
        });

        const objectLinkBtn = {
            type: 'actionButton',
            img: "icon-tool-link",
            dataElement: 'objectLink',
            title: "Navigate to object",
            onClick: () => navigateToObject(false),
        };

        const bimLink = {
            type: 'actionButton',
            label: "3D",
            dataElement: '3DLink',
            title: "Navigate to BIM",
            onClick: () => navigateToObject(true),
        };

        instance.UI.annotationPopup.add(objectLinkBtn);
        instance.UI.annotationPopup.add(bimLink);

        instance.Core.annotationManager.addEventListener('annotationSelected', (annots) => {
            if (annots && annots[0]) {
                instance.Core.Tools.Tool.enableAnnotationHoverCursors();
                instance.UI.enableElements(["annotationCommentButton"])
                switch(true) {
                case annots[0] instanceof instance.Core.Annotations.RectangleAnnotation:
                    instance.UI.disableElements(["annotationCommentButton"])
                    instance.UI.disableElements([bimLink.dataElement])
                    lastSelectedAnnotation = annots[0];
                    if(isReferencingObject(annots[0])){
                        instance.UI.enableElements([objectLinkBtn.dataElement])
                        annots.map(annot => { annot.NoResize = true, annot.NoMove = true })

                        if(isObjectConnectedtoBIM(lastSelectedAnnotation)){
                            instance.UI.enableElements([bimLink.dataElement])
                        }
                    }
                    else{
                        instance.UI.disableElements([objectLinkBtn.dataElement])
                    }
                    break;
                default:
                    break;
                }
            }
        });
    }

    function editorMounted(viewer: WebViewerInstance){
        if(props.readOnly){
            viewOnly(viewer);
            return;
        }

        instance = viewer;
        instance.Core.annotationManager.disableReadOnlyMode();
        instance.Core.annotationManager.promoteUserToAdmin();
        instance.UI.disableElements(['annotationStyleEditButton','linkButton','annotationDeleteButton', 'signaturePanelButton',
            'annotationCommentButton','markReplaceTextToolButton','markInsertTextToolButton','freeTextToolButton','freeHandHighlightToolButton','freeHandToolButton','highlightToolButton','stickyToolButton']);
        instance.UI.setAnnotationContentOverlayHandler((annotation: Core.Annotations.Annotation) => { 
            if(annotation.getCustomData("Object")){
                let json = JSON.parse(annotation.getCustomData("Object"))
                let object = props.objectsDataObject.data.find(row => row.ID == json.ObjectRequirement_ID)
                if(object){
                    const div = document.createElement('div'); 
                    div.innerHTML = `
                    <div style="display: flex; align-items: flex-start;">
                        <div style="flex: 1">
                            <p><b>Object Type:</b> ${object.ObjectType}</p>
                            <p><b>Name / Tag.No:</b> ${object.Object}</p>
                            <p><b>Description:</b> ${object.ObjectDescription ?? ''}</p>
                            <p><b>Org Unit:</b> ${object.ObjectOrgUnit}</p>
                        </div>
                    </div>`
                    return div;
                }
            }
            else{
                const div = document.createElement('div')
                div.appendChild(document.createTextNode(`Created by: ${annotation.Author}`)); 
                return div;
            } 
        });

        const deleteTagBtn = {
            type: 'actionButton',
            img: "ic-delete",
            dataElement: 'deleteTag',
            title: "Delete",
            onClick: () => {instance.Core.annotationManager.deleteAnnotation(lastSelectedAnnotation, { force: true });},
        };
        const addTagBtn = {
            type: 'actionButton',
            img: "icon-menu-add",
            dataElement: 'addTag',
            title: "Add Object/Tag",
            onClick: () => connectObject(),// objLookupRef.value.showModal(),
        };
        const editTagBtn = {
            type: 'actionButton',
            img: "icon-tool-pen-line",
            dataElement: 'editTag',
            title: "Edit Object/Tag",
            onClick: () => objLookupRef.value.showModal(),
        };
        const objectLinkBtn = {
            type: 'actionButton',
            img: "icon-tool-link",
            dataElement: 'objectLink',
            title: "Navigate to object",
            onClick: () => navigateToObject(false),
        };

        const bimLink = {
            type: 'actionButton',
            label: "3D",
            dataElement: '3DLink',
            title: "Navigate to BIM",
            onClick: () => navigateToObject(true),
        };
        instance.UI.annotationPopup.add(deleteTagBtn);
        instance.UI.annotationPopup.add(addTagBtn);
        instance.UI.annotationPopup.add(editTagBtn);
        instance.UI.annotationPopup.add(objectLinkBtn);
        instance.UI.annotationPopup.add(bimLink);
        if(userSession.isDeveloper){
            const annotInfoBtn = {
                type: 'actionButton',
                img: "icon-info",
                dataElement: 'annotInfo',
                title: "Annotation Info (Developers Only)",
                onClick: () => {
                    let annot = props.annotsDataObject.data.find(row => row.AnnotID == lastSelectedAnnotation.Id)
                    console.log(annot)
                    console.log(annot.AnnotID)
                    annotationModalRef.value.showDialog();
                },
            };

            instance.UI.annotationPopup.add(annotInfoBtn)
        }
        instance.Core.annotationManager.addEventListener('annotationSelected', (annots) => {
            if (annots && annots[0]) {
                instance.Core.Tools.Tool.enableAnnotationHoverCursors();
                instance.UI.disableElements([bimLink.dataElement,objectLinkBtn.dataElement])
                switch(true) {
                case annots[0] instanceof instance.Core.Annotations.RectangleAnnotation:
                    lastSelectedAnnotation = annots[0];
                    props.annotsDataObject.setCurrentIndex(props.annotsDataObject.data.find(row => row.AnnotID == lastSelectedAnnotation.Id)?.index);
                    instance.UI.enableElements([deleteTagBtn.dataElement]);
                    if(isReferencingObject(annots[0])){
                        instance.UI.enableElements([objectLinkBtn.dataElement, editTagBtn.dataElement])
                        instance.UI.disableElements([addTagBtn.dataElement])
                        annots.map(annot => { annot.NoResize = true, annot.NoMove = true })
                        if(isObjectConnectedtoBIM(lastSelectedAnnotation)){
                            instance.UI.enableElements([bimLink.dataElement])
                        }
                    }
                    else{
                        instance.UI.enableElements([addTagBtn.dataElement])
                        instance.UI.disableElements([editTagBtn.dataElement])
                    }
                    break;
                default:
                    instance.UI.disableElements([deleteTagBtn.dataElement,addTagBtn.dataElement]);
                    break;
                }
            }
        });

        instance.Core.Annotations.SelectionModel.setCustomHandlers(instance.Core.Annotations.BoxSelectionModel, {
            testSelection: (annotation, x, y, pageMatrix, zoom, rotation, { selectionModel, originalTestSelection }) => {
                if (annotation instanceof instance.Core.Annotations.RectangleAnnotation) {
                    return instance.Core.Annotations.SelectionAlgorithm.boundingRectTest(annotation, x, y, zoom);
                }
            }
        });

        instance.Core.documentViewer.getTool('AnnotationCreateRectangle').setStyles({
            StrokeColor: new instance.Core.Annotations.Color(255, 165, 0),
            StrokeThickness: 1
        })
    }

    async function searchForTags(){
        let alert = o365alert(`Analysing the drawing...`,"info", { slimVersion: true });
        try{
            searchModalRef.value.hide();
            //const pageText = await instance.Core.documentViewer.getDocument().loadPageText(1);
            const response = await (await fetch(`/api/arena/tag-analysis/${props.fileRef}`))
            const pageText = await response.text();
            if(!response.ok){
                alert.close();
                o365alert(pageText,"danger", { slimVersion: true});
                return;
            }
            const pageInfo = await instance.Core.documentViewer.getDocument().getPageInfo(1);
            const recognizer = new TagRecognizer(
                pageText,
                props.syntaxDataObject.data.map(syntax => { return { value: syntax.Name } }),
                props.id,
                { w: pageInfo.width, h: pageInfo.height },
                instance.Core.documentViewer.getCompleteRotation(1)
            )
            let matches = await recognizer.getMatches();

            await props.objectsDataObject.load();
            await props.annotsDataObject.load();
            alert.close();
            o365alert(`${matches.length} Object(s) have been detected`,"info", { autohide: true, delay: 2500, slimVersion: true});
        }
        catch(ex){
            o365alert(ex,"danger", { slimVersion: true});
        }
    }
    function convertQuadsToRect(quads) {
        let minX = Infinity;
        let maxX = -Infinity;
        let minY = Infinity;
        let maxY = -Infinity;

        quads.forEach(q => {
            let quad = q.getPoints();
            minX = Math.min(minX,quad.x1,quad.x2,quad.x3,quad.x4);
            maxX = Math.max(maxX,quad.x1,quad.x2,quad.x3,quad.x4);
            minY = Math.min(minY,quad.y1,quad.y2,quad.y3,quad.y4);
            maxY = Math.max(maxY,quad.y1,quad.y2,quad.y3,quad.y4);
        });

        if(maxX - minX > 300 || maxY - minY > 300){
            return null
        }

        return {
            x2: maxX,
            x1: minX,
            y2: maxY,
            y1: minY
        }
    }

    function isReferencingObject(annotation){
        let json = annotation.getCustomData("Object");
        if(!json) { return false }
        let object = JSON.parse(json)
        return !!props.objectsDataObject.data.find(row => row.ID == object.ObjectRequirement_ID);
    }

    function isObjectConnectedtoBIM(annotation){
        let json = annotation.getCustomData("Object");
        if(!json) { return false }
        let object = JSON.parse(json)
        return !!props.objectsDataObject.data.find(row => row.ID == object.ObjectRequirement_ID && row.ConnectedToBIM == true);
    }

    async function objectSelected(row){
        let obj = props.objectsDataObject.data.find(obj => obj.Object == row.Name)?.ID;
        if(!obj){
            await props.objectsDataObject.createNew({ Object_ID: row._item.ID})
            await props.objectsDataObject.load();
            obj = props.objectsDataObject.data.find(obj => obj.Object == row.Name)?.ID;
        }
        lastSelectedAnnotation.Locked = false;
        lastSelectedAnnotation.StrokeColor = new instance.Core.Annotations.Color(0, 135, 238);
        lastSelectedAnnotation.setCustomData("Object", JSON.stringify({ ObjectRequirement_ID: obj }))
        lastSelectedAnnotation.Author = row._item.Name;
        instance.Core.annotationManager.trigger('annotationChanged', [[lastSelectedAnnotation], 'modify', {}]);
        instance.Core.annotationManager.updateAnnotation(lastSelectedAnnotation);
        instance.Core.annotationManager.redrawAnnotation(lastSelectedAnnotation);
    }

    function navigateToObject(isBIM){
        let json = lastSelectedAnnotation.getCustomData("Object");
        if(!json) { return false }
        let object = JSON.parse(json)
        let object_id = props.objectsDataObject.data.find(row => row.ID == object.ObjectRequirement_ID).Object_ID
        if(props.navigateToObject){
            props.navigateToObject(isBIM, object_id);
            return;
        }
        window.open(isBIM ? '/bim-link?ObjectIDs=' +  object_id + '&State=3D': 'objects/objectdetails?ID=' + object_id);
    }

    async function connectObject(){
        let text = lastSelectedAnnotation?.Author
        if(text.length == 0 || text == userSession.name)
        {
            await instance.Core.PDFNet.initialize();
            const doc = await instance.Core.documentViewer.getDocument().getPDFDoc();
            const firstPage = await doc.getPage(1);
            const pageHeight = await firstPage.getPageHeight();
            const txt = await instance.Core.PDFNet.TextExtractor.create();
            const rect_coords = await lastSelectedAnnotation?.getRect()
            const rect = new instance.Core.PDFNet.Rect(rect_coords.x1, pageHeight - rect_coords.y1, rect_coords.x2, pageHeight - rect_coords.y2)
            txt.begin(firstPage, rect);
            text = await txt.getAsText()
        }

        objLookupRef.value.showModalAndApplyFilter(text.replace(/\n/g, " "))
    }

    async function createAnnotationFromTemplate(rect, title){
        let annot_primKey = crypto.randomUUID();
        let annot_xfdf = `<?xml version="1.0" encoding="UTF-8" ?>
                <xfdf xmlns="http://ns.adobe.com/xfdf/" xml:space="preserve"><fields /> 
                    <add>
                        <square xmlns="http://ns.adobe.com/xfdf/"
                            page="0" 
                            rect="${annot.x1},${annot.y1},${annot.x2},${annot.y2}"
                            color="#ff0000"
                            flags="print"
                            name="${annot_primKey}"
                            title="${annot.title}"
                            subject="Rectangle" 
                            date="D:20241216112324+01'00'" 
                            creationdate="D:20241216112324+01'00'">
                            <trn-custom-data bytes="{&quot;Object&quot;:&quot;{}&quot;}"/>
                        </square>
                    </add> 
                    <modify />
                    <delete />
                </xfdf>`
        await instance.Core.annotationManager.importAnnotationCommand(annot_xfdf)
        props.annotsDataObject.createNew({
            AnnotID: annot_primKey,
            Annot: annot_xfdf,
            Page: 0
        })
        createAnnotationModalRef.value.hideDialog();
        instance.Core.documentViewer.refreshAll();
        instance.Core.documentViewer.updateView();
    }

    function selectAnnotation(row){
        const annotManager = instance.Core.annotationManager;
        const annot = annotManager.getAnnotationsList()
                    .find(a => a.Id == row.AnnotID)
        
        if(annot){
            annotManager.deselectAllAnnotations();
            annotManager.selectAnnotation(annot);
            annotManager.jumpToAnnotation(annot);
        }
    }
</script>
